@import url(" https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* .NAT:first-of-type:before {
  height: calc(100% - 12px);
  bottom: 0;
  top: auto;

} */

 .NAT::before{
  content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2px;
    background-image: linear-gradient(180deg, #6775ec, #6775ec 60%, transparent 0, transparent);
    /* background-image: linear-gradient(180deg, #6775ec, #6775ec);
     */
    background-size: 2px 20px;
    border: none;
}

.NAT::after {
  content: "";
  position: absolute;
  top: 7px;
  left: -4px;
  background-color: #5258fb;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}


@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  .btn {
    @apply rounded-full bg-[#029247] w-max px-8 py-2 text-white capitalize;
  }
}
